<template>
  <div>
		<hr/>
		<h4 class="subtitle-form">{{$t("commons.list.growth_stage")}}</h4>
    <div v-if="entriesChecked.length > 1 && (this.currentActivity.growthStage === undefined && !reseted)" class="info-container">
      <p class="text-reset">{{ $t("activities.multiple.edit.growthstage") }}</p>
      <RewriteButton :on-click="resetAllGrowthStage"/>
    </div>
    <div v-else>
      <!-- GrowthStage -->
      <Dropdown
        ref="dropdown"
        class="shape-custom-select"
        :options="this.inputForm.growthStages"
        label="designation"
        :value="this.selectedGrowthStage"
        @input="onInput"
        :required="needGrowthstage"
      />
    </div>
  </div>
</template>

<script>
import ActivityModule from "@/store/modules/activity";
import ActivityTypesModule from "@/store/modules/activityTypes";
import CropfieldModule from "@/store/modules/cropfield";
import { mapGetters } from "vuex";
import { CLEAN_HARVEST } from "@/store/modules/harvestsStorage";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import Dropdown from "@/components/activity/edit/Dropdown.vue";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import RewriteButton from "@/components/commons/RewriteButton.vue";

export default {
  name: "edit-growth-stage-activity",
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  props: {
    useForm: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      page: this.useForm,
      designation: "",
      activityType: "",
      growthStage: "",
      selectedGrowthStage: {},
      loadComponent: false,
      reseted: false,
    };
  },
  components: {
    Dropdown,
    RewriteButton
  },
  created() {
    this.init();
  },
  methods: {
    init: async function() {
      this.loadComponent = false;
      await this.$store.dispatch("getAllActivityTypes");
      if (this.inputForm.growthStages !== undefined) {
        // S'il y'a un seul stade de croissance dans le formulaire, on le sélectionne
        if (this.inputForm.growthStages.length === 1) {
          this.selectedGrowthStage = this.inputForm.growthStages[0];
          this.addGrowth(this.selectedGrowthStage.id);
        }
        if (Object.keys(this.currentEntry) == 0) {
          this.loadComponent = true;
          return;
        }
        const checkActivityType = element => element.designation == this.currentEntry.activityType_designation;
        const checkGrowStage = element => element.designation == this.currentEntry.growthStage_designation;

        // Si on modifie une activité on initialise le type activité et stade de croissance
        // à partir de l'activité actuel
        if (this.$route.name === "activity.edit") {
          let index = this.activityTypes.findIndex(checkActivityType);
          this.activityType = this.currentEntry.activityType_designation != "" ? this.activityTypes[index]?.id : "";
          if (
            this.currentEntry.activityType_designation != "Transport" &&
            this.currentEntry.hasOwnProperty("growthStage_designation")
          ) {
            index = this.inputForm.growthStages.findIndex(checkGrowStage);
            if(index >= 0) {
              this.selectedGrowthStage = this.currentEntry.growthStage_designation != "" ? this.inputForm.growthStages[index] : null;
            }
          }
        }
      }
      this.loadComponent = true;
    },
    /**
     * Description: Renvoie l'ID du type d'activité sélectionné.
     * @return {Number}
     */
    getActivityType() {
      if (this.activityType != "") return this.activityType;
      return null;
    },
        /**
     * Description: Renvoie l'état du type d'activité s'il prend des intrants.
     * @return {Boolean}
     */
    getInputNeeded() {
       let el = this.activityTypes.find(f => f.id == this.activityType);
       return el;
    },

    // /**
    //  * Description: Retourne un boolean informant si l'activité a besoin  d'inputs ou non.
    //  * @return {Boolean}  True si des inputs sont nécessaires sinon false.
    //  */
    // getNeedInputs() {
    //   return this.needInputs;
    // },

    /**
     * Description: Renvoie l'ID du growthStage sélectionné.
     * @return {Number}
     */
    getGrowthStage() {
      if (this.growthStage != "") return this.growthStage;
      return null;
    },

    /**
     * Description: Renvoie l'attribut d'un type activité selon le nom de l'attribut de comparaison renseigné
     * (l'ID par defaut) et la valeur de celui-ci.
     * @param {}            attrValue La valeur de l'attribut que l'on connaît.
     * @param {String}      attrResearched Le nom de l'attribut que l'on recherche.
     * @param {String}      attrCmp Le nom de l'attribut par lequel comparer la value de attrValue
     *                          (l'ID par défaut).
     * @return {}           La valeur de retour dépend de l'attribut recherché.
     */
    getActivityTypeAttribut(attrValue, attrResearched, attrCmp = "id") {
      let actType = this.activityTypes.filter(function(elem) {
        if (elem[attrCmp] == attrValue) return elem;
      });

      return actType[0][attrResearched];
    },
    addGrowth(value) {
      this.growthStage = value;
    },
    onInput(growthStage) {
      this.addGrowth(growthStage ? growthStage.id : null)
    },
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },
    resetAllGrowthStage(){
      this.reseted = true
      this.$store.dispatch(this.storeEditAction, {growthStage: null});
    }
  },
  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["activity"]) {
        store.registerModule("activity", ActivityModule);
      }
      if (!store.state["cropfield"]) {
        store.registerModule("cropfield", CropfieldModule);
      }
      if (!store.state["activityTypes"]) {
        store.registerModule("cropfield", ActivityTypesModule);
      }
    }
  },
  computed: {
    ...mapGetters({
        currentActivity: "activity/currentEntry",
        inputForm: "activity/inputForm",
        activityTypes: "activityTypes",
        currentActivityType: "activityTypes",
        entriesChecked: "activity/entriesChecked",
    }),
    needInputs() {
      if (this.currentEditedEntry.activityType_needInputs != null) return this.currentEditedEntry.activityType_needInputs;
      return this.currentEntry.activityType_needInputs;
    },
    activityLoaded() {
      return this.$store.getters["activity/entryLoaded"];
    },
    needGrowthstage() {
      return this.inputForm.needGrowthstage === true
    }
  },
  watch: {
    currentEntry: {
      handler: function() {
        this.init();
      },
      deep: true,
    },
    growthStage: {
      deep: true,
      handler: function() {
        this.$store.dispatch(this.storeEditAction, {growthStage: this.growthStage});
      }
    },
    inputForm: {
      deep: true,
      handler() {
        // S'il y'a un seul élément dans la liste, il est automatiquement sélectionné
        if (this.inputForm?.growthStages?.length == 1) {
          this.selectedGrowthStage = this.inputForm.growthStages[0];
          this.addGrowth(this.selectedGrowthStage.id);
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
/**
 * Parameter error cropfield.
 */
.error-select-cropfield {
  // text-align: center;
  color: $danger-color;
}

/**
 * Inputs params.
 */
.inputs-params {
  margin: 2px 10px 3px 0px;
  // width: 47%;
}

/**
 * Inputs margin.
 */
.input-margin {
  margin-left: 30px;
}

/**
 * Subtitle params.
 */
.subtitle-params {
  margin: auto 0px 2px 0px;
}

/**
 * Parameter error worked surface.
 */
.error-worked-surface {
  // text-align: center;
  color: $danger-color;
}

.info-container {
  display: flex;
  align-items: center; /* Centre verticalement les éléments */
  justify-content: space-between; /* Place le texte à gauche et le bouton à droite */
}

.text-reset {
    font-size: 14px;
    padding: 5px;
    color: #222e50;
    margin: 5px;
}

</style>
